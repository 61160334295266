<template>
  <div class="flex flex-col">
    <h1 class="text-theme-sidebr text-2xl font-poppins mb-1">{{ $t(locale.title) }}</h1>
    <div class="flex justify-between items-center">
      <div class="md:w-1/4">
        <b-form-input
            @input="search"
            placeholder="Sök"
        />
      </div>
      <div>
        <button @click="openAddModal = true"
                class="flex c-p-2 w-full text-center focus:outline-none bg-theme-sidebar text-white whitespace-nowrap rounded-md">
                    <span
                        class="whitespace-nowrap text-center w-full text-base"
                        v-text="
                            'Add Member Category'
                        "
                    />
        </button>
      </div>
    </div>
    <b-card class="mt-3">
      <b-row>
        <b-col
            v-for="data in items.results"
            :key="data.uuid"
            cols="12"
            sm="6"
            lg="4"
        >
          <div class="custom-border rounded-md p-1 mb-1">
            <b-card-title
                class="d-flex mb-0 justify-content-between align-items-center"
            >
              <p class="mb-0">{{ data.name }}</p>

              <div class="d-flex smallGap">
                <!-- <mdicon
                    class="text-danger"
                    role="button"
                    :width="20"
                    :height="20"
                    name="trash-can"
                /> -->
                <b-button
                    size="sm"
                    variant="outline-secondaryColor"
                    block
                    @click="openModlEdit(data)"
                    v-text="$t(locale.edit)"
                />
              </div>
            </b-card-title>
            <hr/>
            <b-card-text class="mt-1">
              <div class="h-full">
                <div class="d-flex justify-content-between">
                  <p
                      class="mb-0 w-50 text-secondaryColor"
                      id="label"
                  >
                    DESCRIPTION
                  </p>
                  <p class="mb-0 w-50">
                    {{ data.name }}
                  </p>
                </div>

                <hr/>

                <div class="d-flex justify-content-between">
                  <p
                      class="mb-0 w-50 text-secondaryColor"
                      id="label"
                  >
                    NUMBERS OF MEMBERS
                  </p>
                  <p class="mb-0 w-50">
                    Består av {{ data.num_present_members }} medlem(mar)
                  </p>
                </div>

                <!-- <hr />

                <div class="d-flex justify-content-between">
                    <p
                        class="mb-0 w-50 text-secondaryColor"
                        id="label"
                    >
                        RECURRING BILLING
                    </p>
                    <p class="mb-0 w-50">
                        In total this segment gets billed 250k
                        monthly
                    </p>
                </div> -->
              </div>
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <CustomNoHeaderModalVue v-if="openUpdateCategory" width="w-11/12 md:w-1/2" :title="''"
                            @handleClose="openUpdateCategory = false" :close="!true">
      <div class="flex flex-col p-1" slot="header">
        <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">Update Member Category</h2>
      </div>
      <div class="relative flex flex-col p-1 z-50" slot="body">
        <ValidationObserver ref="form">

          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div class="flex">
              <validation-provider
                  #default="{errors}"
                  :rules="'required'"
                  :name="'name'"
              >
                <b-form-group
                    label="Name"
                >
                  <b-form-input
                      v-model="categoryForm.name"
                  />
                  <small
                      class="text-danger"
                      v-text="errors[0]"
                  />
                </b-form-group>
              </validation-provider>
            </div>

            <div class="flex w-full">
              <validation-provider
                  class="w-full"
                  #default="{errors}"
                  :rules="'required'"
                  :name="'fee rule'"
              >
                <b-form-group
                    class="w-full"
                    label="Fee Rule"
                >
                  <v-select class="w-full" label="name" v-model="categoryForm.fee_rule" :filterable="false"
                            :options="feesResults" @search="searchFees">
                    <template slot="no-options">
                      Sök...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                  <small
                      class="text-danger"
                      v-text="errors[0]"
                  />
                </b-form-group>
              </validation-provider>
            </div>

          </div>
          <div class="col-span-2 flex flex-col mt-3">
            <div class="flex gap-4 item-center w-full mb-3">
              <h5 v-if="addMember">Add Members to member category</h5>
              <h5 v-if="!addMember">Remove Members from member category</h5>
              <div class="flex flex-col">
                <svg @click="toggle(true)" :class="addMember ? 'text-theme-sidebar' : ''"
                     xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
                </svg>

                <svg @click="toggle(false)" :class="addMember ? '' : 'text-theme-sidebar'"
                     xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18"/>
                </svg>
              </div>
              <span>
                            <b-form-input
                                class="w-1/2"
                                placeholder="Sök"
                                @input="searchTableMembers"
                            />
                        </span>
            </div>
            <div v-if="addMember" class="grid grid-cols-1 md:grid-cols-1 custom-border rounded-md">
              <div class="h-60 overflow-y-scroll">
                            <span class="w-full flex justify-center mt-3" v-if="loadingMembers">
                                <b-spinner
                                    small
                                    type="grow"
                                />
                                {{ $t('Message.loading') }}...
                            </span>
                <table v-else class="table w-full z-50 relative mb-7">
                  <thead>
                  <tr>
                    <th class="whitespace-nowrap p-2 md:p-0">
                                            <span class="text-xs text-gray-400">
                                                <b-form-checkbox
                                                    v-model="selected"
                                                    @change="performAllChecked"
                                                    :value="checked"
                                                />
                                            </span>
                    </th>
                    <th class="whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Golf ID</span></th>
                    <th class="whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Member</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="member in copyMainMembers" :key="member.uuid">
                    <td>
                                            <span>
                                                <b-form-checkbox
                                                    @change="performSingleChecked"
                                                    v-model="member.checkbox"
                                                />
                                            </span>
                    </td>
                    <td>
                      <span>{{ member.golf_id }}</span>
                    </td>
                    <td>
                      <span>{{ member.first_name + ' ' + member.last_name }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
            <div v-if="!addMember" class="grid grid-cols-1 md:grid-cols-1 custom-border rounded-md">
              <div class="h-60 overflow-y-scroll border-l">
                <table class="table w-full z-50 relative mb-7">
                  <thead>
                  <tr>
                    <th class="whitespace-nowrap p-2 md:p-0">
                                            <span class="text-xs text-gray-400">
                                                <b-form-checkbox
                                                    v-model="selectedRemoved"
                                                    @change="performAllRemoveChecked"
                                                    :value="checked"
                                                />
                                            </span>
                    </th>
                    <th class="whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Golf ID</span></th>
                    <th class="whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Member</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(member, i) in finalMember" :key="i">
                    <td>
                                            <span class="flex justify-start">
                                                <b-form-checkbox
                                                    @change="performSingleRemove"
                                                    v-model="member.checkbox"
                                                />
                                            </span>
                    </td>
                    <td>
                      <span>{{ member.golf_id }}</span>
                    </td>
                    <td>
                      <span>{{ member.name }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="addMember" class="w-full flex justify-between mt-2">
              <div class="flex justify-between gap-10">
                <paginate
                    v-model="memberPage"
                    :page-count="memberPage"
                    :click-handler="firePagination"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :page-class="'page-item c-mr-1 border-unset'"
                    prev-link-class='page-link c-mr-1 border-unset'
                    next-link-class='page-link c-mr-1 border-unset'
                    next-class='next-item'
                    prev-class='prev-item'
                    page-link-class='page-link c-mr-1 border-unset'
                    active-class='active c-mr-1 border-unset'
                    :container-class="'pagination  b-pagination'">
                </paginate>
              </div>
              <div>
                <b-button
                    v-if="markMembers.length > 0"
                    @click="updateMemberCategory('ADD')"
                    size="sm"
                    v-text="processing ? $t('Message.loading') : 'Add Members'"
                />
              </div>
            </div>
            <div v-if="!addMember" class="mt-1">
              <b-button
                  v-if="markRemoveMembers.length > 0"
                  @click="updateMemberCategory('REMOVE')"
                  size="sm"
                  variant="danger"
                  v-text="processing ? $t('Message.loading') : 'Remove Members'"
              />
            </div>
          </div>
          <div class="w-full flex justify-end">
            <b-button
                @click="openUpdateCategory = false"
                variant="outline-danger"
                v-text="'Close'"
            />
          </div>
        </ValidationObserver>
      </div>
    </CustomNoHeaderModalVue>

    <CustomNoHeaderModalVue v-if="openAddModal" width="w-11/12 md:w-1/3" :title="''" @handleClose="closeModal"
                            :close="!true">
      <div class="flex flex-col p-1" slot="header">
        <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">Create Member Category</h2>
      </div>
      <div class="relative flex flex-col p-1 z-50" slot="body">
        <ValidationObserver ref="form">

          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div class="flex">
              <validation-provider
                  #default="{errors}"
                  :rules="'required'"
                  :name="'name'"
              >
                <b-form-group
                    label="Name"
                >
                  <b-form-input
                      v-model="categoryForm.name"
                  />
                  <small
                      class="text-danger"
                      v-text="errors[0]"
                  />
                </b-form-group>
              </validation-provider>
            </div>

            <div class="flex w-full">
              <b-form-group
                  class="w-full"
                  label="Fee Rule"
              >
                <v-select class="w-full" label="name" v-model="categoryForm.fee_rule" :filterable="false"
                          :options="feesResults" @search="searchFees">
                  <template slot="no-options">
                    Sök...
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.name }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.name }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </div>

            <div class="col-span-2">
              <div class="grid grid-cols-4 gap-3 mb-1">
                                <span v-for="(item, i) in selectedMembers" :key="item.uuid"
                                      class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white">
                                    <small>{{ item.first_name + ' ' + item.last_name }}</small>
                                    <span class="cursor-pointer" @click="removeMember(i)">
                                        <feather-icon icon="Trash2Icon"/>
                                    </span>
                                </span>
              </div>
            </div>

            <div class="flex w-full">
              <b-form-group
                  class="w-full"
                  label="Add members"
              >
                <v-select class="w-full" label="first_name" v-model="emptyCate" @option:selected="setMembers($event)"
                          :filterable="false" :options="members" @search="searchMembers">
                  <template slot="no-options">
                    Sök...
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.first_name + ' ' + option.last_name }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.first_name + ' ' + option.last_name }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </div>
          </div>
          <div class="w-full flex justify-end">
            <b-button
                :disabled="processing"
                size="md"
                variant="success"
                v-text="processing ? $t('Message.loading') : 'Create'"
                @click="createMemberCategory('CREATE')"
            />
          </div>
        </ValidationObserver>
      </div>
    </CustomNoHeaderModalVue>

    <!--<CustomNoHeaderModalVue v-if="openUpdateCategory" width="w-11/12 md:w-1/3"  :title="''"  @handleClose="openUpdateCategory = false" :close="!true">
        <div class="flex flex-col p-1" slot="header">
            <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">Update Member Category</h2>
        </div>
        <div class="relative flex flex-col p-1 z-50" slot="body">
            <ValidationObserver ref="form">

                <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div class="flex">
                        <validation-provider
                            #default="{errors}"
                            :rules="'required'"
                            :name="$t(locale.formDate)"
                        >
                        <b-form-group
                            label="Name"
                        >
                            <b-form-input
                                v-model="selectedData.name"
                            />
                            <small
                                class="text-danger"
                                v-text="errors[0]"
                            />
                        </b-form-group>
                        </validation-provider>
                    </div>

                    <div class="flex w-full">
                        <b-form-group
                            class="w-full"
                            label="Fee Rule"
                        >
                            <v-select class="w-full" label="name" v-model="selectedData.fee_rule" :filterable="false" :options="feesResults" @search="searchFees">
                                <template slot="no-options">
                                    Sök...
                                </template>
                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.name }}
                                    </div>
                                </template>
                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.name }}
                                    </div>
                                </template>
                            </v-select>
                        </b-form-group>
                    </div>

                    <div class="col-span-2">
                        <div class="grid grid-cols-4 gap-3 mb-1">
                            <span v-for="(item, i) in selectedMembers" :key="i" class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white">
                                <small v-if="item.name">{{ item.name }}</small>
                                <small v-else>{{ item.first_name + ' '+item.last_name }}</small>
                                <span class="cursor-pointer" @click="removeMember(i)">
                                    <feather-icon icon="Trash2Icon" />
                                </span>
                            </span>
                        </div>
                    </div>

                    <div class="flex w-full">
                        <b-form-group
                            class="w-full"
                            label="Add members"
                        >
                            <v-select class="w-full" label="first_name" v-model="emptyCate"  @option:selected="setMembers($event)" :filterable="false" :options="members" @search="searchMembers">
                                <template slot="no-options">
                                    Sök...
                                </template>
                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.first_name + ' '+option.last_name  }}
                                    </div>
                                </template>
                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.first_name + ' '+option.last_name }}
                                    </div>
                                </template>
                            </v-select>
                        </b-form-group>
                    </div>
                </div>
                <div class="w-full flex justify-end">
                    <b-button
                        :disabled="processing"
                        size="md"
                        variant="success"
                        v-text="processing ? $t('Message.loading') : 'Update'"
                        @click="createMemberCategory"
                    />
                </div>
            </ValidationObserver>
        </div>
    </CustomNoHeaderModalVue> -->
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import vSelect from 'vue-select';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import Paginate from 'vuejs-paginate'
import {mapGetters} from "vuex";


export default {
  name: 'MemberCategory',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    CustomNoHeaderModalVue,
    'paginate': Paginate
  },
  data() {
    return {
      config: useAppConfig(),
      items: {results: []},
      openUpdateCategory: false,
      selectedMembers: [],
      memberPage: 1,
      categoryForm: {
        fee_rule: {},
        add_member_uuids: [],
        // remove_member_uuids: [],
        name: ''
      },
      processing: false,
      openAddModal: false,
      selectedData: {},
      markMembers: [],
      markRemoveMembers: [],
      selected: false,
      selectedRemoved: false,
      loadingMembers: true,
      checked: 'selected',
      mainMembers: {},
      copyMainMembers: [],
      memberAddArray: [],
      memberRemoveArray: [],
      finalMember: [],
      addMember: true,
      feeType: [],
      emptyCate: '',
      feesResults: [],
      members: [],
      memberParams: {
        limit: 10,
      },
      queryParams: {
        limit: 30,
      },
      pagination: {
        currentPage: 1,
        totalProducts: 0,
      },
      locale: {
        title: 'memberCategoryCard.title',
        addSegment: 'CustomersCard.addSegment',
        edit: 'edit',
      },
    }
  },
  computed: {
    message() {
       return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    features() {
      if (this.getLang == '') {
        return this.swedishLang ? 'features' : 'features';
      } else return this.getLang == 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE:'appConfig/getLocale'
    })
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getUserSubscriptions()
    this.getMemberCategory()
    this.getAllMembers()
  },
  methods: {
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      })
    },
    toggle(bool) {
      this.addMember = bool
      // if (bool) {
      //     this.finalMember = this.memberAddArray
      //     this.updateMainTableList(bool)
      // } else {
      //     this.finalMember = this.memberRemoveArray
      //     this.updateMainTableList(bool)
      // }
    },
    closeModal() {
      this.selectedData = {}
      this.selectedMembers = []
      this.openAddModal = false
    },
    search: debounce(function (e) {
      if (e) {
        this.getMemberCategory({URL: '/?search=' + escape(e), method: 'get'})
      } else {
        this.getMemberCategory()
      }
    }, 350),
    getMemberCategory(init = null) {
      const mode = init ? init : {URL: '/', method: 'get'}
      this.$useJwt.getMembersCategory(mode)
          .then(res => {
            this.items = res.data.data
          })
    },
    removeMember(index) {
      this.selectedMembers.splice(index, 1)
    },
    getFeeType() {
      const mode = {URL: '/', method: 'get'}
      this.$useJwt.getFeesTypes(mode)
          .then(res => {
            this.feeType = res.data.data
          })
    },
    searchFees(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteFees(loading, search, this);
      }
    },
    searchRemoteFees: debounce(function (loading, search, vm) {
      this.$useJwt.getFeeRules({URL: '?search=' + escape(search), method: 'get'})
          .then(res => {
            this.feesResults = res.data.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    searchMembers(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteMembers(loading, search, this);
      }
    },
    searchRemoteMembers: debounce(function (loading, search, vm) {
      this.$useJwt.getMembers({URL: '?search=' + escape(search), method: 'get'})
          .then(res => {
            this.members = res.data.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    setMembers(item) {
      const uuid = this.selectedMembers.filter(el => el.uuid === item.uuid)
      if (uuid.length < 1) {
        this.selectedMembers.push(item)
      }
      this.emptyCate = ''
    },
    createMemberCategory(mode) {
      this.$refs.form.validate().then(res => {
        if (res) {
          this.processing = true
          const payload = {...this.categoryForm}

          this.selectedMembers.map(el => {
            if (el.uuid) {
              payload['add_member_uuids'].push(el.uuid)
            }
          })

          this.$useJwt.getMembersCategory({URL: '', method: 'post'}, payload)
              .then(res => {
                this.openAddModal = false
                this.getMemberCategory()
                this.processing = false
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.message],
                    'CheckIcon',
                    'success'
                );
                this.selectedMembers = []
                this.selectedData = {}
              })
              .catch(err => {
                this.processing = false
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data[this.message],
                    'AlertTriangleIcon',
                    'danger'
                );
              })
        }
      })
    },
    updateMemberCategory(mode) {
      this.$refs.form.validate().then(res => {
        if (res) {
          this.processing = true
          const payload = this.categoryForm

          if (mode === 'ADD') {
            payload['add_member_uuids'] = this.markMembers.map(el => {
              return el.uuid
            })
            payload['remove_member_uuids'] = []
          } else {
            payload['add_member_uuids'] = []
            payload['remove_member_uuids'] = this.markRemoveMembers.map(el => {
              return el.uuid
            })
          }

          this.$useJwt.getMembersCategory({URL: `/${this.selectedData.uuid}`, method: 'put'}, payload)
              .then(res => {
                this.clearFieldForm()
                this.getMemberCategory()
                this.getAllMembers()
                this.processing = false
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.message],
                    'CheckIcon',
                    'success'
                );
              })
              .catch(err => {
                this.processing = false
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data[this.message],
                    'AlertTriangleIcon',
                    'danger'
                );
              })
        }
      })
    },
    openModlEdit(data) {
      this.categoryForm = {
        name: data.name,
        fee_rule: data.fee_rule
      }
      this.selectedData = data
      this.finalMember = [...data.present_members.map(el => {
        return {...el, checkbox: false}
      })]
      this.performMemberArrangement()
      this.openUpdateCategory = true
    },
    performMemberArrangement() {
      let array = []
      this.mainMembers.data.results.map(el => {
        const filter = this.finalMember.filter(item => item.golf_id === el.golf_id)
        if (filter.length < 1) {
          array.push(el)
        }
      })
      this.copyMainMembers = array
    },
    performAllChecked(e) {
      this.copyMainMembers.forEach(element => {
        element.checkbox = this.selected === 'selected' ? true : false
      })
      this.verifyMarks()
    },
    performSingleChecked(e) {
      this.verifyMarks()
    },
    performAllRemoveChecked(e) {
      this.finalMember.forEach(element => {
        element.checkbox = this.selectedRemoved === 'selected' ? true : false
      })
      this.verifyRemoveMarks()
    },
    performSingleRemove() {
      this.verifyRemoveMarks()
    },
    updateMainTableList(bool) {
      let list = []
      if (bool) {
        this.copyMainMembers.map(el => {
          const filter = this.memberAddArray.filter(item => item.uuid === el.uuid)
          if (filter.length < 1) {
            list.push({...el, checked: false})
          }
        })
        this.copyMainMembers = [...list]
      } else {
        this.copyMainMembers.map(el => {
          const filter = this.memberRemoveArray.filter(item => item.uuid === el.uuid)
          if (filter.length < 1) {
            list.push(el)
          }
        })
        this.copyMainMembers = [...list]
      }
      this.verifyMarks()
    },
    verifyMarks() {
      const filter = this.copyMainMembers.filter(item => item.checkbox)
      if (filter.length > 0) {
        this.markMembers = filter
      } else {
        this.markMembers = []
      }
    },
    verifyRemoveMarks() {
      const filter = this.finalMember.filter(item => item.checkbox)
      if (filter.length > 0) {
        this.markRemoveMembers = filter
      } else {
        this.markRemoveMembers = []
      }
    },
    processMembers() {
      if (this.addMember) {
        this.markMembers.map(el => {
          const filter = this.memberAddArray.filter(element => el.uuid === element.uuid)
          if (filter.length < 1) {
            this.memberAddArray.push(el)
          }
        })
        this.finalMember = this.memberAddArray
      } else {
        this.markMembers.map(el => {
          const filter = this.memberRemoveArray.filter(element => el.uuid === element.uuid)
          if (filter.length < 1) {
            this.memberRemoveArray.push(el)
          }
        })
        this.finalMember = this.memberRemoveArray
      }
      this.updateMainTableList(this.addMember)
    },
    async firePagination(number) {
      this.memberPage = number
      this.mainMembers.page = number;
      await this.getAllMembers();
      await this.performMemberArrangement()
    },
    searchTableMembers: debounce(function (e) {
      if (e) {
        this.memberParams.search = e
        this.getAllMembers()
      } else {
        delete this.memberParams.search
        this.getAllMembers()
      }
    }, 350),
    getAllMembers() {
      this.loadingMembers = true
      this.$useJwt.getMembers({URL: '', method: 'get'}, {params: this.memberParams})
          .then(res => {
            this.loadingMembers = false
            this.mainMembers = res.data
            this.copyMainMembers = res.data.data.results.map(el => {
              return {
                ...el,
                checkbox: false
              }
            })
            this.updateMainTableList(this.addMember)
          })
    },
    clearFieldForm() {
      this.openAddModal = false
      this.openUpdateCategory = false
      this.memberAddArray = []
      this.memberRemoveArray = []
      this.categoryForm.add_member_uuids = []
      this.categoryForm.remove_member_uuids = []
      this.categoryForm.name = ''
      this.categoryForm.fee_rule = {}
      this.getAllMembers()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .table th {
  padding: .3rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table tbody, td {
  // padding-top: .4rem;
  padding: .3rem !important;
  // padding-bottom: .4rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
}
</style>